import Route from "ZiggyRoute";
import Router from "ZiggyRouter";

export const ZiggyVue = {
    install: (v, options) => {
        const r = (name, params, absolute, config = options) =>
            route(name, params, absolute, config);

        v.mixin({
            methods: {
                route: r,
            },
        });

        if (parseInt(v.version) > 2) {
            v.provide("route", r);
        }

        v.config.globalProperties.route = (
            name,
            params,
            absolute,
            config = Ziggy
        ) => route(name, params, absolute, config);
    },
};

function route(name, params, absolute, config) {
    const router = new myRouter(name, params, absolute, config);

    return name ? router.toString() : router;
}

class myRouter extends Router {
    /**
     * Replace the constructor to use the name with the locale prefix, if needed.
     */
    constructor(name, params, absolute, config) {
        super();

        this._config =
            config ??
            (typeof Ziggy !== "undefined" ? Ziggy : globalThis?.Ziggy);
        this._config = { ...this._config, absolute };

        if (name) {
            if (!this._config.routes[name]) {
                name = this.translatedRouteName(name);
            }

            if (!this._config.routes[name]) {
                throw new Error(
                    `Ziggy error: route '${name}' is not in the route list.`
                );
            }

            this._route = new Route(
                name,
                this._config.routes[name],
                this._config
            );
            this._params = this._parse(params);
        }
    }

    /**
     * Overload the current() method to use the name with the locale prefix, if needed.
     */
    current(name, params) {
        return super.current(this.translatedRouteName(name), params);
    }

    translatedRouteName(name) {
        return this.needTranslation(name)
            ? this._config.locale + "." + name
            : name;
    }

    needTranslation(name) {
        if (
            typeof this._config.locale == "undefined" ||
            typeof name != "string" ||
            name.startsWith("api.")
        ) {
            return false;
        }

        return true;
    }
}
