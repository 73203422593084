import DataObjectParser from "dataobject-parser";

export function objectMap(obj, fn) {
    return Object.fromEntries(
        Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
    );
}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, key)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function getTimesBetween(start, end, step = "day", stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export function capitalFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ucFirst(string) {
    return capitalFirstLetter(string);
}

export function arrayUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export function arrayUniqueByFn(array, fn) {
    return array.filter((b, i) => array.findIndex(a => fn(a, b)) === i);
}

export function arrayUniqueByKey(array, key = 'id') {
    array = array.filter(e => e);
    return arrayUnique(array, (a, b) => a[key] === b[key]);
}

export function lcFirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function centsToMoney(cents) {
    return String((cents / 100).toFixed(2)).replace(".", ",");
}

export function getMeta(content, index) {
    return {
        is_first: index === 0,
        is_last: content.length - 1 === index,
        nextContentType: content[index + 1]?.type,
        previousContentType: content[index - 1]?.type,
    };
}

export function toSelect(objectOrArray, nullCase = 'Select an option', emptyCase = 'No options available') {
    if (Array.isArray(objectOrArray)) {
        if (objectOrArray.length === 0) {
            return [{label: emptyCase, value: ''}];
        }

        return [{label: nullCase, value: ''}, ...objectOrArray];
    }

    if (Object.keys(objectOrArray).length === 0) {
        return [{label: emptyCase, value: ''}];
    }

    return {'': nullCase, ...objectOrArray};
}

export function blank(value) {
    return value === null || value === undefined || value === '';
}

export function notBlank(value) {
    return !blank(value);
}

export function allBlank(...values) {
    return values.every(blank);
}

export function anyBlank(...values) {
    return values.some(blank);
}

export function arrayIncludesAny(array, values) {
    array = array.map((value) => value.toLowerCase());
    values = values.map((value) => value.toLowerCase());

    return values.some((value) => array.includes(value));
}


export function take(array, count, offset = 0) {
    while (array.length < count) {
        array = [...array, ...array];
    }
    return array.slice(offset, offset + count);
}
